/* global Swiper:false anime:false  */

(function () {
	"use strict";

	// swiper for hero slider

	let heroSwiper = null;
	let currentBanner;

	// set boolen to perform the slide animations for one time only
	var animate_regressBanner = true,
		animate_totalAi = true,
		animate_truriskEliminate = true,
		animate_gartnerCnapp = true,
		animate_totalCloudRisk = true,
		animate_qscBanner = true,
		animate_qscMumbaiBanner = true,
		animate_etmBanner = true,
		// always keep the regular slides for future use: QSC, CRS events. don't forget to uncomment the slides in the partial first
		animate_crsBanner = true;


	function animateFirstSlide(){
		// place the animation for the first slide here, the animation will be played on swiper load event

		if (animate_qscBanner) {
			animate_qscBanner = false;
			anime({
				targets: ".js-qsc-slide-anim .anim",
				translateY: [100, 0],
				opacity: [0, 1],
				duration: 700,
				easing: "easeOutQuint",
				delay: anime.stagger(250)
			});
		}
	}

	// Function to create the animation sequence
	function animateDots() {

		const dots = document.querySelectorAll(".js-trurisk-dots-svg .red-dot");

		let timeline = anime.timeline({
			easing: "linear",
			loop: true
		});

		// Add animation for each dot sequentially
		for (let i = 0; i < dots.length; i += 2) {
			if (dots[i] && dots[i + 1]) {
				timeline.add({
					targets: [dots[i], dots[i + 1]],
					opacity: [0, 1, 0],
					duration: 1000,
					easing: "easeInOutSine",
					delay: anime.stagger(250)
				});
			}
		}
	}

	const initHeroSwiper = () => {
		heroSwiper = new Swiper(".js-home-hero-slider", {
			slidesPerView: 1,
			centeredSlides: false,
			autoplay: {
				delay: 8000, // duration of each slide in ms
				disableOnInteraction: false,
				pauseOnMouseEnter: true
			},
			autoHeight: false,
			loop: false,
			pagination: {
				el: ".js-home-hero-slider .swiper-pagination",
				clickable: true,
			},
			on: {
				// animate the first slide on load event
				init: animateFirstSlide,
				// animate other slides on slide change event
				slideChange: function () {
					if(animate_qscMumbaiBanner) {
						animate_qscMumbaiBanner = false;
						anime({
							targets: ".js-qsc-mumbai-slide-anim .anim",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});

					}
					if(animate_etmBanner) {
						animate_etmBanner = false;
						anime({
							targets: ".q-etm .anim",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});

					}

					var activeIndex = this.activeIndex;
					var activeSlide = this.slides[activeIndex];
					currentBanner = activeSlide.dataset.banner;

				if(currentBanner === "totalAi" && animate_totalAi ){
						console.log('3');
						animate_totalAi = false;
						anime({
							targets: ".js-totalAi-slide-txt .stgr",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});

						// start animating trurisk dots in loop on slider init
						animateDots();
				}


					var activeIndex = this.activeIndex;
					var activeSlide = this.slides[activeIndex];
					currentBanner = activeSlide.dataset.banner;

				 if (currentBanner === "regressBanner" && animate_regressBanner) {
						animate_regressBanner = false;
						anime({
							targets: ".js-regress-slide-txt .stgr",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});
					}
					else if (currentBanner === "truriskEliminate" && animate_truriskEliminate) {

						animate_truriskEliminate = false;
						anime({
							targets: ".js-trurisk-slide-txt .stgr",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});
						anime({
							targets: ".js-trurisk-dots",
							opacity: [0, 1],
							duration: 1200,
							easing: "easeOutQuint",
							delay: 800
						});
					}
					else if (currentBanner === "totalCloudRisk" && animate_totalCloudRisk) {
						animate_totalCloudRisk = false;
						anime({
							targets: ".js-total-cloud-risk-slide-txt .stgr",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});
					}
					else if (currentBanner === "crsBanner" && animate_crsBanner) {
						animate_crsBanner = false;
						anime({
							targets: ".js-crs-slide-anim .anim",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});
					}
					else if (currentBanner === "gartnerCNAPP" && animate_gartnerCnapp) {

						animate_gartnerCnapp = false;
						anime({
							targets: ".js-gartner-cnapp-content .anim",
							translateY: [100, 0],
							opacity: [0, 1],
							duration: 700,
							easing: "easeOutQuint",
							delay: anime.stagger(250)
						});
						anime({
							targets: ".js-gartner-cnapp-img",
							translateY: [50, 0],
							opacity: [0, 1],
							duration: 750,
							easing: "linear",
							delay: 350
						});
					}

					// animate QSC banner
					// else if(currentBanner === "qscBanner" && animate_qscBanner) {
					// 	animate_qscBanner = false;
					// 	anime({
					// 		targets: ".js-qsc-slide-anim .anim",
					// 		translateY: [100, 0],
					// 		opacity: [0, 1],
					// 		duration: 700,
					// 		easing: "easeOutQuint",
					// 		delay: anime.stagger(250)
					// 	});
					//}
				}
			}
		});
	};

	const handleHeroSwiper = () => {
		if (!heroSwiper && document.querySelector(".js-home-hero-slider")) {
			initHeroSwiper();
		}
	};
	window.addEventListener("load", handleHeroSwiper);
	window.addEventListener("resize", handleHeroSwiper);

	//Swiper for Blog container

	let blogSwiper = null;
	const initBlogSwiper = () => {
		blogSwiper = new Swiper(".js-blog-container", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 32,
			pagination: {
				el: ".js-blog-container .swiper-pagination",
				clickable: true,
			}
		});
	};

	const handleBlogSwiper = () => {
		if (!blogSwiper && document.querySelector(".js-blog-container")) {
			initBlogSwiper();
		}
	};
	window.addEventListener("load", handleBlogSwiper);
	window.addEventListener("resize", handleBlogSwiper);

	//Swiper for events carousel

	let eventsCarousel = null;
	const initEventsCarousel = () => {
		eventsCarousel = new Swiper(".js-events-carousel", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 32,
			pagination: false
		});
	};

	const handleEventsCarousel = () => {
		if (!eventsCarousel && document.querySelector(".js-events-carousel")) {
			initEventsCarousel();
		}
	};
	window.addEventListener("load", handleEventsCarousel);
	window.addEventListener("resize", handleEventsCarousel);

	// events slider

	let eventsSlider = null;
	const initEventsSlider = () => {
		eventsSlider = new Swiper(".js-events-slider", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 32,
			pagination: {
				el: ".js-events-slider .swiper-pagination",
				clickable: true,
			}
		});
	};

	const handleEventsSlider = () => {
		if (!eventsSlider && document.querySelector(".js-events-slider")) {
			initEventsSlider();
		}
	};
	window.addEventListener("load", handleEventsSlider);
	window.addEventListener("resize", handleEventsSlider);

	// init desktop hero total cloud risk carousel
	new Swiper(".q-total-cloud-risk", {
		spaceBetween:50,
		slidesPerView: 2,
		centeredSlides: true,
		loop: true,
		direction: 'vertical',
		navigation: false,
		allowTouchMove: false, // Disable dragging
		speed: 800,
		autoplay: {
			delay: 2500
		},
		effect: 'coverflow',
		coverflowEffect: {
		  rotate: 0,
		  slideShadows: false
		},
		 breakpoints: {
			920: {
				spaceBetween: 150,
			}
		}
	});

	new Swiper(".q-total-cloud-risk-mobile", {
		spaceBetween: 10,
		slidesPerView: 1.3,
		centeredSlides: true,
		loop: true,
		allowTouchMove: false, // Disable dragging
		navigation: false,
		autoplay:true,
		breakpoints: {
			640: {
				spaceBetween:20,
			}
		}
	});

})();